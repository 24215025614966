import React, { useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { GlobalStyle } from '@components/layout';
import Logo from '@assets/images/lookout.png';
import LiveActionVideo from '@assets/images/live-action.mp4';
import AnimationVideo from '@assets/images/animation.mp4';
import AnimationMasthead from '@assets/images/animation-masthead.mp4';
import LiveActionMasthead from '@assets/images/live-action-masthead.mp4';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import LiveActionThumb from '@assets/images/live-action-thumb.jpg';
import AnimationThumb from '@assets/images/animation-thumb.jpg';

const Video = styled.video`
  object-fit: cover;
  width: 100%;
  height: 100%;

  @media (max-width: 600px) {
    display: none;
  }
`;

const Thumbnail = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 1;
  transition: opacity 0.5s;

  @media (max-width: 600px) {
    // position: static;
    // height: 100%;
    // opacity: 1;
  }
`;

const HalfScreen = styled.section`
  width: 50%;
  height: 100vh;
  position: relative;

  @media (max-width: 600px) {
    width: 100%;
    height: 300px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const Header = styled.header`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  z-index: 2;

  img {
    width: 700px;
    margin-bottom: 20px;

    @media (max-width: 600px) {
      width: 200px;
    }
  }

  p {
    width: 80%;
    margin: 0 auto;
    color: white;
    font-size: 18px;
    line-height: 22px;

    @media (max-width: 600px) {
      width: 100%;
      line-height: 19px;
      font-size: 14px;
    }
  }

  @media (max-width: 600px) {
    position: static;
    background-color: black;
    transform: none;
    padding: 40px;
  }
`;

const Container = styled.section`
  display: flex;

  @media (max-width: 600px) {
    display: block;
  }
`;

const Gradient = styled.div`
  height: 200px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
  @media (max-width: 600px) {
    display: none;
  }
`;

const Category = styled.div`
  position: absolute;
  left: 50%;
  bottom: 100px;
  transform: translate(-50%, 0);
  z-index: 10;

  @media (max-width: 600px) {
    position: static;
    transform: none;
  }

  a {
    color: white;
    text-transform: uppercase;
    font-weight: var(--font-weight-black);
    text-decoration: none;
    font-size: 40px;

    &:hover {
      text-decoration: underline;
    }

    @media (max-width: 600px) {
      font-size: 26px;
    }
  }
`;

export default function Work({ location }) {
  const animationVideoRef = useRef(null);
  const liveActionVideoRef = useRef(null);
  const liveActionThumbRef = useRef(null);
  const animationThumbRef = useRef(null);

  const breakpoints = useBreakpoint();
  const showVideo = !breakpoints.sm;

  const playVideo = (video, thumb) => {
    video.current.play();
    thumb.current.style.opacity = '0';
  };

  const pauseVideo = (video, thumb) => {
    video.current.pause();
    // video.current.currentTime = 0;
    thumb.current.style.opacity = '1';
  };

  return (
    <main>
      <GlobalStyle />
      <Helmet>
        <title>Lookout Content</title>
        <link rel="canonical" href="https://lookoutcontent.com" />
        <link rel="preload" type="video/mp4" href="foo.mp4" />
      </Helmet>
      <Header>
        <h1>
          <img src={Logo} alt="Lookout" />
        </h1>
        <p>
          Lookout is a creative company that makes films. From the initial
          concept, through the various phases of production, all the way to
          launch, our collaborative method pairs an ambitious creative team with
          resourceful producers to create work that is both inspired and
          effective.
        </p>
      </Header>
      <Container>
        <HalfScreen>
          {showVideo && (
            <Video ref={liveActionVideoRef} loop muted preload="auto">
              <source src={LiveActionVideo} type="video/mp4" />
            </Video>
          )}
          <Thumbnail
            ref={liveActionThumbRef}
            src={LiveActionThumb}
            alt="Lookout"
          />
          <Category>
            <Link
              onMouseEnter={() =>
                playVideo(liveActionVideoRef, liveActionThumbRef)
              }
              onMouseLeave={() =>
                pauseVideo(liveActionVideoRef, liveActionThumbRef)
              }
              to="/live-action"
            >
              Live-action
            </Link>
          </Category>
        </HalfScreen>
        <HalfScreen>
          {showVideo && (
            <Video ref={animationVideoRef} loop muted preload="auto">
              <source src={AnimationVideo} type="video/mp4" />
            </Video>
          )}
          <Thumbnail
            ref={animationThumbRef}
            src={AnimationThumb}
            alt="Lookout"
          />

          <Category>
            <Link
              onMouseEnter={() =>
                playVideo(animationVideoRef, animationThumbRef)
              }
              onMouseLeave={() =>
                pauseVideo(animationVideoRef, animationThumbRef)
              }
              to="/animation"
            >
              Animation
            </Link>
          </Category>
        </HalfScreen>
        <Gradient />
        <Video style={{ display: 'none' }} muted preload="auto">
          <source src={AnimationMasthead} type="video/mp4" />
        </Video>
        <Video style={{ display: 'none' }} muted preload="auto">
          <source src={LiveActionMasthead} type="video/mp4" />
        </Video>
      </Container>
    </main>
  );
}
